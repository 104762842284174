export let environment = {
    "envName": "prod",
    "appType": "desktop",
    "version": "30.3.8+87",
    "pwaName": "Ackermans",
    "config": {
        "api": {
            "websiteBasePath": "https://www.ackermans.co.za",
            "middlewareBasePath": "https://www.ackermans.co.za",
            "paymentShopperResultUrl": "https://magento2.ackermans.co.za/default/pp-hosted/secure/payment",
            "genericFormCompanyNo": "2",
            "genericFormOrigin": "ackermans.co.za",
            "genericFormContactUsDestID": "15",
            "genericFormSocialInvestmentDestID": "19",
            "newsletterAPIBrand": "ackermans",
            "emailPlaceholderReg": "^[0-9]+@(.+)?((magento2\\.ackermans\\.co\\.za)|(pepkorit\\.com))",
            "cdnBaseUrl": "https://www.ackermans.co.za/cdn-proxy/prod-ack-cdn",
            "cdnRootUrl": "/assets/cdn",
            "magentoImageBaseUrl": "https://www.ackermans.co.za/cdn-proxy/prod-ack-cdn/product-images/prod",
            "documentBaseUrl": "/assets/cdn/documents",
            "attributeStylesUrl": "/assets/cdn/attribute-styles/ackermans.co.za-attributes.144d55a3a9a1cbcf2eb0daf98e94cf90.css",
            "attributeStylesFontName": "ackermanscoza-attributes",
            "brandLogoImageBaseUrl": "https://www.ackermans.co.za/cdn-proxy/prod-ack-cdn/brand-logos/prod",
            "productAttributeAssetBaseUrl": "https://www.ackermans.co.za/cdn-proxy/prod-ack-cdn/product-attributes/prod",
            "storeFinderBrand": "Ackermans",
            "tenant": "Ackermans",
            "addThisTracker": "ra-5b967421ee443b4d",
            "wordpressURL": "https://wordpress.ackermans.co.za",
            "wordpressRootPages": [
                "ack"
            ],
            "wordpressRootPageMap": [
                {
                    "key": "ack",
                    "websiteBasePath": "https://www.ackermans.co.za"
                },
                {
                    "key": "cube",
                    "websiteBasePath": "https://www.cube.fashion"
                }
            ],
            "wordpressBlogPostsEnabled": false,
            "production": true,
            "firebaseUploadPath": "ackermans/prod",
            "firebaseConfig": [
                "QUl6YVN5QnJhNE5jMzR5a0hjSkc0Z24zU0RjRGtlRHVneVZRaFk0",
                "YWNrZXJtYW5zLXByb2QuZmlyZWJhc2VhcHAuY29t",
                "aHR0cHM6Ly9hY2tlcm1hbnMtcHJvZC5maXJlYmFzZWlvLmNvbQ==",
                "YWNrZXJtYW5zLXByb2Q=",
                "YWNrZXJtYW5zLXByb2QuYXBwc3BvdC5jb20=",
                "NjUzMjk1NTg3OTIx",
                "MTo2NTMyOTU1ODc5MjE6d2ViOjkxMWM1NWQzOWI3ZmU5MjA2MjllNzA=",
                "Ry1GRUhHVzBWWjg1",
                "QkNNbHpRYlFhX0x2VHlIQkZzZmxPcXdJSUxnQ1p2UWRRRVlxbFNpMmNTaTZpRjVYYVlKQVV6bGpfWUY3TlVwYjYwV1U4b1U4TjVOY1owZDBRdDIzTzVF"
            ],
            "gtmTracker": "GTM-W32B2F9",
            "sentryDSN": "https://29f651cfd45942b8b5223f087922486a@o967548.ingest.sentry.io/5952058",
            "sentryAllowUrls": [
                "ackermans.co.za"
            ],
            "recaptcha": "6Lcx0i0kAAAAAB96vUgdu3ldEpObWasZcb8DeSe6",
            "nativeAssetPath": "",
            "crazyeggScriptUrl": "//script.crazyegg.com/pages/scripts/0117/2924.js",
            "imagekitWordpressEnabled": true,
            "imagekitProductImagesEnabled": true,
            "imagekitProductImagesUrl": "https://www.ackermans.co.za/imagekit/prod-ack-product-images",
            "imagekitWordpressMediaUrl": "https://www.ackermans.co.za/imagekit/prod-ack-wordpress/prod",
            "segmentifyEnabled": false,
            "segmentifyScriptUrl": "//cdn.segmentify.com/499e93c7-6123-4b9f-b71c-b8aeb5ddc14b/segmentify.js",
            "storeCreditEnabled": false,
            "seoBase": {
                "title": "Ackermans | No.1 for Value",
                "description": "Shop the latest affordable fashion for women, kids, babies and cellular deals from Ackermans; South Africa's value retailer. ",
                "ogType": "website",
                "twitterType": "card",
                "twitterSiteUsername": null,
                "twitterAuthorUsername": null,
                "facebookAppId": null,
                "imagePath": null,
                "imageWidth": null,
                "imageHeight": null
            }
        },
        "customPageSEOData": [
            {
                "id": 4,
                "urlKey": "bra-guide",
                "pageTitle": "Bra Guide",
                "seoTitle": "The Bra Guide | Ackermans",
                "seoDescription": "Find out your true bra size by using our Ackermans Bra Guide. View our range of Perfect fit bra's."
            },
            {
                "id": 5,
                "urlKey": "the-character-shop/downloads",
                "pageTitle": "Lion King Downloads",
                "seoTitle": "Lion King Downloads | Ackermans",
                "seoDescription": "Download our free Lion King activity pages."
            },
            {
                "id": 6,
                "urlKey": "the-character-shop/movie-trailer",
                "pageTitle": "Lion King Movie Trailer",
                "seoTitle": "Lion King Movie Trailer | Ackermans",
                "seoDescription": "Lion King Movie Trailer."
            },
            {
                "id": 7,
                "urlKey": "find-out-more/social-investment/application-form",
                "pageTitle": "Sponsorship Form",
                "seoTitle": "Find Out More | Social Investment Application | Ackermans",
                "seoDescription": "Apply for the corporate social investment programme"
            },
            {
                "id": 8,
                "urlKey": "find-out-more/social-investment/application-form/thank-you",
                "pageTitle": "Thank You",
                "seoTitle": "Find Out More | Social Investment Application | Ackermans",
                "seoDescription": "Apply for the corporate social investment programme"
            }
        ],
        "defaultLatLng": {
            "latitude": -33.919706,
            "longitude": 18.419986
        },
        "headOfficeLatLng": {
            "latitude": -33.941226,
            "longitude": 18.68835
        },
        "redirects": [
            {
                "sourcePath": "/products/cellular/promotions",
                "targetURL": "https://shop.ackermans.co.za/promotions.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/promotions",
                "targetURL": "https://shop.ackermans.co.za/promotions.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/products/cellular/cellphones",
                "targetURL": "https://shop.ackermans.co.za/cellphone.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/cellphones",
                "targetURL": "https://shop.ackermans.co.za/cellphone.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/products/cellular/premio",
                "targetURL": "https://shop.ackermans.co.za/premio.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/premio",
                "targetURL": "https://shop.ackermans.co.za/premio.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/products/cellular/tablets-and-laptops",
                "targetURL": "https://shop.ackermans.co.za/tablets-and-laptops.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/tablets-and-laptops",
                "targetURL": "https://shop.ackermans.co.za/tablets-and-laptops.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/products/cellular/accessories",
                "targetURL": "https://shop.ackermans.co.za/accessories.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/accessories",
                "targetURL": "https://shop.ackermans.co.za/accessories.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/products/cellular/dstv",
                "targetURL": "https://shop.ackermans.co.za/decoders.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/product/cellular/dstv",
                "targetURL": "https://shop.ackermans.co.za/decoders.html",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            },
            {
                "sourcePath": "/page/click-collect",
                "targetURL": "/page/delivery-options",
                "newWindowWhenClicked": true,
                "includeChildPages": true
            }
        ],
        "sisterSites": {
            "ackermans": "https://www.ackermans.co.za/",
            "cube": "https://www.cube.fashion/",
            "connect": "https://shop.ackermans.co.za/"
        }
    },
    "uiConfig": {
        "desktopMainMenu": {
            "items": [
                {
                    "type": 1,
                    "urlKeyPath": "/promotions",
                    "cssClasses": "promotions",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/promotions"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/women",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/clothing"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/women/clothing",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/lingerie-sleepwear",
                                        "cssClasses": "title padding-right-30"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/women/lingerie-sleepwear",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/shoes"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/women/shoes",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/accessories"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/women/accessories",
                                        "style": 1
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/beauty"
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/limited-edition"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/the-lingerie-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/the-activ-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/the-denim-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/women/the-beauty-shop"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/women/promotions"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/beauty",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/makeup"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/makeup",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/bath-body"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/bath-body",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/skincare"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/skincare",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/oral-care"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/oral-care",
                                        "style": 1
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/fragrance"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/fragrance",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/beauty/brands"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/beauty/brands",
                                        "style": 1
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/baby",
                    "dropDown": {
                        "startUnderButton": false,
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/newborn"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/newborn",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/boys-3-36-months"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/boys-3-36-months",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/girls-3-36-months"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/girls-3-36-months",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/baby-essentials"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/baby-essentials",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/limited-edition"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/the-character-shop"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/the-character-shop",
                                        "style": 1
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/baby/promotions"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/lilly-sid"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/turtledove-london"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/baby/my-first-wardrobe"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/kids",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/boys-2-10-years"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/boys-2-10-years",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/girls-2-10-years"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/girls-2-10-years",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/boys-7-15-years"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/boys-7-15-years",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/girls-7-15-years"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/girls-7-15-years",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/school"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/school",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/limited-edition"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/the-denim-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/the-character-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/back-to-school"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/kids/promotions"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/kids/lilly-sid"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/teens",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/shop-all"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/teens/shop-all",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/teen-boys"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/teens/teen-boys",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/teen-girls"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/teens/teen-girls",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/brands"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/teens/brands",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/teens/promotions"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/the-denim-shop"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/teens/limited-edition"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/character",
                    "dropDown": {
                        "startUnderButton": false,
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/character/baby"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/character/baby",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/character/kids"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/character/kids",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/character/homeware"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/character/homeware",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/character/brands"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/character/brands",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/character/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/character/promotions"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/homeware",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/bathroom"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/bathroom",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/bedroom"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/bedroom",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/drinkware-lunchboxes"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/drinkware-lunchboxes",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/gym-equipment"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/gym-equipment",
                                        "style": 1
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/party-decorations"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/party-decorations",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/homeware/limited-edition"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/limited-edition",
                                        "style": 1
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/homeware/promotions"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/connect",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/accessories"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/accessories",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/cellphones"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/cellphones",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/connectivity"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/connectivity",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/decoders-accessories"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/decoders-accessories",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/laptops-tablets"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/laptops-tablets",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/connect/sims"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/connect/sims",
                                        "style": 1
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/tech",
                    "dropDown": {
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/audio"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/tech/audio",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/power-banks-chargers"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/tech/power-banks-chargers",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/accessories"
                                    },
                                    {
                                        "type": 3,
                                        "parentUrlKeyPath": "/tech/accessories",
                                        "style": 1
                                    }
                                ]
                            },
                            {
                                "items": [
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/sale",
                                        "style": 0,
                                        "cssClasses": "on-sale"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/promotion-1"
                                    },
                                    {
                                        "type": 1,
                                        "urlKeyPath": "/tech/promotion-2"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 1,
                    "urlKeyPath": "/cellular",
                    "dropDown": {
                        "startUnderButton": true,
                        "columns": [
                            {
                                "items": [
                                    {
                                        "type": 4,
                                        "parentUrlKeyPath": "/cellular"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    "type": 0,
                    "cssClasses": "plusmore",
                    "locale": "menu.plusMore",
                    "link": "/page/plusmore"
                }
            ],
            "sizes": {
                "autoLayoutGap": 10,
                "title": 24,
                "subTitle": 24,
                "buttonLarge": 41,
                "buttonStd": 35,
                "divider": 21
            }
        },
        "footerMenuElements": [
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.customerService",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/terms-and-conditions/general#returns-exchange",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.returnsFAQ",
                            "builtInPageType": "full-link",
                            "pathAfterType": "/page/terms-and-conditions/general#returns-exchange"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.deliveryDetails",
                            "builtInPageType": "full-link",
                            "pathAfterType": "/page/delivery-options#delivery"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.howToOrder",
                            "builtInPageType": "full-link",
                            "pathAfterType": "/page/delivery-options#how-to-buy-online"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.storeLocator",
                            "builtInPageType": "store-finder",
                            "pathAfterType": "/page/terms-and-conditions/general#returns-exchange"
                        }
                    ]
                },
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.ackShop",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/deals",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/women",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/baby",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/kids",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/homeware",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/cellular",
                            "enableChildren": false
                        },
                        {
                            "type": "CATEGORY_BUTTON",
                            "urlKeyPath": "/character",
                            "enableChildren": false
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.profile",
                    "builtInPageType": "account",
                    "pathAfterType": "account-details",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.registerSignIn",
                            "builtInPageType": "auth",
                            "pathAfterType": "login",
                            "authenticated": false
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.myDetails",
                            "builtInPageType": "account",
                            "pathAfterType": "account-details"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.account.ackAccount",
                            "builtInPageType": "account",
                            "pathAfterType": "ack-account"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "localeKey": "menu.account.laybys",
                            "builtInPageType": "account",
                            "pathAfterType": "laybys"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.corporateInfo",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/corporate-information/about-us",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.aboutUs",
                            "pathAfterType": "/page/corporate-information/about-us"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.more.careers",
                            "pathAfterType": "/page/corporate-information/careers"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.csi",
                            "pathAfterType": "/page/corporate-information/csi"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "store-directory",
                            "localeKey": "menu.storeDirectory"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "store-finder",
                            "localeKey": "menu.findAStore"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.newStores",
                            "pathAfterType": "/page/new-stores"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.ackermansSound",
                            "pathAfterType": "/page/the-sound-of-ackermans"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "contact-us",
                            "localeKey": "menu.contactUs"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.custom.explore",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/catalogues",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.catalogs",
                            "pathAfterType": "/page/catalogues"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.magazine",
                            "pathAfterType": "https://magazine.ackermans.co.za"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.competitions",
                            "pathAfterType": "/page/competitions"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.babyCorner",
                            "pathAfterType": "/page/the-baby-corner"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "builtInPageType": "full-link",
                    "localeKey": "menu.financialServices",
                    "pathAfterType": "/page/financial-solutions",
                    "selectable": true,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.ackAccountApplication",
                            "pathAfterType": "https://apply.ackermans.co.za/"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.ackAccountCard",
                            "pathAfterType": "/page/financial-solutions/account-card"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.layby",
                            "pathAfterType": "/page/financial-solutions/lay-by"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.insurance",
                            "pathAfterType": "/page/financial-solutions/insurance"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.loans",
                            "pathAfterType": "/page/financial-solutions/loans"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.billPayments",
                            "pathAfterType": "/page/financial-solutions/bill-payments"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.moneyTransfer",
                            "pathAfterType": "/page/financial-solutions/money-transfers"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.custom.finSolMoreServices",
                            "pathAfterType": "/page/financial-solutions/more-services"
                        }
                    ]
                }
            ],
            [
                {
                    "type": "CUSTOM_BUTTON",
                    "localeKey": "menu.terms",
                    "builtInPageType": "full-link",
                    "pathAfterType": "/page/terms-and-conditions/general",
                    "selectable": false,
                    "children": [
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms",
                            "pathAfterType": "/page/terms-and-conditions/general"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.pepkorPrivacyPolicy",
                            "pathAfterType": "/page/terms-and-conditions/general#external-privacy-statement"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.cookiePolicy",
                            "pathAfterType": "/page/terms-and-conditions/general#cookie-policy"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.competitionRules",
                            "pathAfterType": "/page/terms-and-conditions/competitions"
                        },
                        {
                            "type": "CUSTOM_BUTTON",
                            "builtInPageType": "full-link",
                            "localeKey": "menu.terms.promotionOfAccess",
                            "pathAfterType": "/page/terms-and-conditions/general#paia"
                        }
                    ]
                }
            ]
        ],
        "footerTermsLinkElements": [
            {
                "type": "CUSTOM_BUTTON",
                "localeKey": "menu.terms",
                "builtInPageType": "full-link",
                "pathAfterType": "/page/terms-and-conditions/general",
                "selectable": false,
                "children": [
                    {
                        "type": "CUSTOM_BUTTON",
                        "builtInPageType": "full-link",
                        "localeKey": "menu.terms",
                        "pathAfterType": "/page/terms-and-conditions/general"
                    },
                    {
                        "type": "CUSTOM_BUTTON",
                        "builtInPageType": "full-link",
                        "localeKey": "menu.terms.pepkorPrivacyPolicy",
                        "pathAfterType": "/page/terms-and-conditions/general#external-privacy-statement"
                    },
                    {
                        "type": "CUSTOM_BUTTON",
                        "builtInPageType": "full-link",
                        "localeKey": "menu.terms.cookiePolicy",
                        "pathAfterType": "/page/terms-and-conditions/general#cookie-policy"
                    },
                    {
                        "type": "CUSTOM_BUTTON",
                        "builtInPageType": "full-link",
                        "localeKey": "menu.terms.competitionRules",
                        "pathAfterType": "/page/terms-and-conditions/competitions"
                    },
                    {
                        "type": "CUSTOM_BUTTON",
                        "builtInPageType": "full-link",
                        "localeKey": "menu.terms.promotionOfAccess",
                        "pathAfterType": "/page/terms-and-conditions/general#paia"
                    }
                ]
            }
        ],
        "noContentMenuElements": [
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/deals",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/women",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/baby",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/kids",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/homeware",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/cellular",
                "enableChildren": false
            },
            {
                "type": "CATEGORY_BUTTON",
                "urlKeyPath": "/character",
                "enableChildren": false
            }
        ],
        "catalogs": [
            {
                "label": "Cellular Catalogue",
                "url": "https://www.yumpu.com/en/embed/view/KVMP1wg5NdEBoLTJ",
                "image": "/catalogs/2019-11/cellular-catalogue.jpg",
                "startDate": "2019-11-14T00:00:00.000Z",
                "endDate": "2021-12-22T00:00:00.000Z"
            },
            {
                "label": "Character Catalogue",
                "url": "https://www.yumpu.com/en/embed/view/DNZyhg9OAl1enaj0",
                "image": "/catalogs/2019-11/character-catalogue.jpg",
                "startDate": "2019-11-25T00:00:00.000Z",
                "endDate": "2021-12-22T00:00:00.000Z"
            }
        ],
        "disablePromotionalPages": false,
        "homePageBannerRatio": {
            "width": 1920,
            "height": 737
        },
        "promotionalPageBannerRatio": {
            "width": 1920,
            "height": 737
        },
        "subPromotionalPageBannerRatio": {
            "width": 1740,
            "height": 306
        },
        "iframeExternalPages": [
            {
                "urlKey": "example",
                "url": "https://pepstores-dev-81df7.firebaseapp.com/iframe-kitchen/",
                "title": "Example"
            }
        ],
        "productListSortFields": [
            {
                "localeKey": "product.sort.priceLowToHigh",
                "sortField": "price",
                "sortDescending": false
            },
            {
                "localeKey": "product.sort.priceHighToLow",
                "sortField": "price",
                "sortDescending": true
            },
            {
                "localeKey": "product.sort.latest",
                "sortField": "position",
                "sortDescending": false
            }
        ],
        "attributeCodes": [
            {
                "name": "Features",
                "type": "NAME_AND_ICON",
                "singleAttrCodes": [
                    {
                        "code": "alarm",
                        "icon": "alarm"
                    },
                    {
                        "code": "battery",
                        "icon": "battery-full"
                    },
                    {
                        "code": "bluetooth",
                        "icon": "bluetooth"
                    },
                    {
                        "code": "camera",
                        "icon": "camera"
                    },
                    {
                        "code": "connects_10_devices",
                        "icon": "sitemap"
                    },
                    {
                        "code": "dual_sim",
                        "icon": "sim-card"
                    },
                    {
                        "code": "easypress_playstartup",
                        "icon": "button-check"
                    },
                    {
                        "code": "exclusive",
                        "icon": "medal-outline-star"
                    },
                    {
                        "code": "facebook",
                        "icon": "facebook"
                    },
                    {
                        "code": "flash",
                        "icon": "flash"
                    },
                    {
                        "code": "games",
                        "icon": "gamepad"
                    },
                    {
                        "code": "google_playstore",
                        "icon": "play"
                    },
                    {
                        "code": "handset_ram_size",
                        "icon": "ram"
                    },
                    {
                        "code": "headset",
                        "icon": "headphone"
                    },
                    {
                        "code": "internet_browser",
                        "icon": "web"
                    },
                    {
                        "code": "memory_card_included",
                        "icon": "sd-card"
                    },
                    {
                        "code": "memory_card_slot",
                        "icon": "markdown"
                    },
                    {
                        "code": "mp3_player",
                        "icon": "ipod"
                    },
                    {
                        "code": "os",
                        "icon": "android"
                    },
                    {
                        "code": "radio",
                        "icon": "radio-tower"
                    },
                    {
                        "code": "screen",
                        "icon": "phone"
                    },
                    {
                        "code": "speakerphone",
                        "icon": "sound-alt"
                    },
                    {
                        "code": "torch",
                        "icon": "flashlight"
                    },
                    {
                        "code": "twitter",
                        "icon": "twitter"
                    },
                    {
                        "code": "value_adds",
                        "icon": "plus"
                    },
                    {
                        "code": "vibrate",
                        "icon": "phone-home"
                    },
                    {
                        "code": "voicecall",
                        "icon": "microphone"
                    },
                    {
                        "code": "whatsapp",
                        "icon": "whatsapp"
                    },
                    {
                        "code": "wifi",
                        "icon": "wifi-3"
                    },
                    {
                        "code": "bluetooth_speaker",
                        "icon": "music-speaker-streamline"
                    },
                    {
                        "code": "data_100mb_12mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "data_100mb_6mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "data_200mb_6mnths",
                        "icon": "plus"
                    },
                    {
                        "code": "free_airtime",
                        "icon": "plus"
                    },
                    {
                        "code": "free_cover",
                        "icon": "mobile"
                    },
                    {
                        "code": "free_data",
                        "icon": "plus"
                    },
                    {
                        "code": "free_sdcard",
                        "icon": "sd-card"
                    },
                    {
                        "code": "free_stand",
                        "icon": "mobile"
                    },
                    {
                        "code": "free_starter_pack",
                        "icon": "mobile"
                    },
                    {
                        "code": "otg_adapter",
                        "icon": "plus"
                    },
                    {
                        "code": "screen_protector",
                        "icon": "mobile"
                    },
                    {
                        "code": "screen_wipe",
                        "icon": "mobile"
                    },
                    {
                        "code": "tempered_glass",
                        "icon": "mobile"
                    },
                    {
                        "code": "whatsapp_1gb_3months",
                        "icon": "plus"
                    },
                    {
                        "code": "external_memory_slot",
                        "icon": "sd-card"
                    },
                    {
                        "code": "2_back_covers",
                        "icon": "mobile"
                    },
                    {
                        "code": "bluetooth_speakers",
                        "icon": "sound-alt"
                    },
                    {
                        "code": "protective_cover",
                        "icon": "mobile"
                    },
                    {
                        "code": "free_whatsapp_3gb_300mb",
                        "icon": "plus"
                    },
                    {
                        "code": "free_whatsapp_3gb_600mb",
                        "icon": "plus"
                    },
                    {
                        "code": "laptop_bag_mouse_r169",
                        "icon": "mouse"
                    },
                    {
                        "code": "fm_radio",
                        "icon": "radio-tower"
                    },
                    {
                        "code": "two_back_covers",
                        "icon": "mobile"
                    },
                    {
                        "code": "microsd_slot",
                        "icon": "database-5"
                    },
                    {
                        "code": "vibrate_alert",
                        "icon": "phone-on"
                    },
                    {
                        "code": "speakerphone",
                        "icon": "speaker-louder"
                    },
                    {
                        "code": "internet_browser",
                        "icon": "chrome"
                    },
                    {
                        "code": "google_playstore",
                        "icon": "play"
                    },
                    {
                        "code": "games",
                        "icon": "ios-football"
                    }
                ],
                "singleIncludeCodes": []
            }
        ],
        "showScrollToTop": false
    },
    "data": {
        "contactEmail": "",
        "contactNumber": "0860 900 100",
        "contactNumberInternational": "+27 (0)21 928 1040",
        "facebookLink": "https://www.facebook.com/AckermansSA",
        "youTubeLink": "https://www.youtube.com/user/AckermansStores",
        "instagramLink": "https://www.instagram.com/ackermans_za/",
        "linkedInLink": "https://www.linkedin.com/company/ackermans/",
        "androidAppUrl": "https://play.google.com/store/apps/details?id=com.ackermans.app&hl=en_ZA",
        "iosAppUrl": "https://itunes.apple.com/za/app/ackermans/id1082618469?mt=8"
    },
    "build": {
        "implPath": "src/implementation/ack/main/desktop",
        "addNoIndex": false
    }
};